import { DirectionType, WidgetIconStyle } from "app-types/messenger";
import React, { ReactNode } from "react";
import BubbleIcon from "./BubbleIcon";
import { StyledMessengerPreview } from "./index.style";
import { getContrastColor } from "utils/color";

interface MessengerPreviewProps {
  children?: ReactNode;
  withBubble?: boolean;
  accentColor: string;
  direction: DirectionType;
}

const MessengerPreview = ({
  children,
  direction,
  withBubble,
  accentColor,
}: MessengerPreviewProps) => {
  const style = WidgetIconStyle.Default;
  const contrastColor = getContrastColor(accentColor);
  return (
    <StyledMessengerPreview direction={direction}>
      {children}
      {withBubble && (
        <BubbleIcon
          direction={direction}
          accentColor={accentColor}
          contrastColor={contrastColor}
          style={style}
        />
      )}
    </StyledMessengerPreview>
  );
};

export default MessengerPreview;
