import {
  ChatTemplate as ChatTemplateType,
  SignupTemplateType,
} from "app-types/template";
import GlobalWrapper from "components/core/GlobalWrapper";
import React from "react";
import SEO from "components/core/SEO";
import { useTranslation } from "react-i18next";
import { CHAT_TEMPLATES_PATH } from "constants/paths";
import { TemplateContainer } from "./index.style";
import MessengerPreview from "components/ui/extended/MessengerPreview";
import Notification from "components/ui/extended/MessengerPreview/Notification";
import CommunityAuthorBox from "components/ui/extended/CommunityAuthorBox";
import { HeaderContainer, PageContainer } from "styles/global.style";
import { Row } from "styled-bootstrap-grid";
import { TemplateBackground } from "templates/templates/canned-response/index.style";
import { DescriptionCol } from "components/ui/base/ImageHeader/index.style";
import Fade from "components/core/Animation/extended/Fade";
import Heading from "components/core/Typography/Heading";
import Breadcrumbs from "components/core/Breadcrumbs";
import SafeLink from "components/core/SafeLink";
import Button from "components/core/Button";
import { CTAColor } from "app-types/color";
import NumberSteps from "components/ui/base/NumberSteps";
import VideoPreview from "components/ui/base/VideoPreview";
import RegisterForCustomerService from "components/ui/extended/RegisterForCustomerService";
import Customers from "components/ui/extended/Customers";
import { getTemplateSignupUrl } from "utils/path";
import { theme } from "styles/theme";
import { COMMUNITY_AUTHOR } from "constants/community";
import BlogPostsBox from "components/ui/base/BlogPostsBox";

export interface ChatTemplateProps {
  pageContext: {
    template: ChatTemplateType;
  };
  location: Location;
}

const ChatTemplate = ({ pageContext, location }: ChatTemplateProps) => {
  const { t } = useTranslation();

  return (
    <GlobalWrapper
      withLayout
      layoutSettings={{ withCta: false }}
      location={location}
    >
      <SEO
        title={pageContext.template.name}
        description={pageContext.template.description}
        image={{
          relativePath: "meta/community-templates-for-saas.jpg",
          alt: pageContext.template.name,
        }}
        withoutTitleSuffix
      />
      <HeaderContainer>
        <PageContainer>
          <Row>
            <TemplateContainer lg={7} order={1} lgOrder={1}>
              <TemplateBackground show>
                <Fade direction={"up"}>
                  <MessengerPreview
                    withBubble
                    direction={"left"}
                    accentColor={theme.accentColor}
                  >
                    <Notification
                      name={COMMUNITY_AUTHOR.name}
                      accentColor={theme.accentColor}
                      accountId={COMMUNITY_AUTHOR.id}
                      messages={[
                        pageContext.template.chat.actions[0].contents[0].content
                          .content || "",
                      ]}
                    />
                  </MessengerPreview>
                </Fade>
              </TemplateBackground>
            </TemplateContainer>
            <DescriptionCol lg={5} order={2} lgOrder={2}>
              <Fade direction={"up"}>
                <Breadcrumbs
                  elements={[
                    {
                      name: "Customerly",
                      path: "/",
                    },
                    {
                      name: "Templates",
                      path: "/templates/",
                    },
                    {
                      name: "Live Chat Templates",
                      path: `${CHAT_TEMPLATES_PATH}/`,
                    },
                  ]}
                />
              </Fade>

              <Fade direction={"up"}>
                <Heading level={1}>{pageContext.template.name}</Heading>
              </Fade>

              <Fade direction={"up"} delay={500}>
                <p>{pageContext.template.description}</p>
              </Fade>

              <Fade direction={"up"} delay={1000}>
                <Button
                  as={SafeLink}
                  to={getTemplateSignupUrl(
                    SignupTemplateType.Trigger,
                    pageContext.template.trigger_template_id
                  )}
                  icon={{ type: "chevron-right" }}
                  ctaColor={CTAColor.Yellow}
                >
                  {t("templates.templates.chat.useTemplate")}
                </Button>
              </Fade>
              <Fade direction={"up"} delay={1200}>
                <CommunityAuthorBox author={pageContext.template.author} />
              </Fade>
            </DescriptionCol>
          </Row>
        </PageContainer>
      </HeaderContainer>

      <NumberSteps
        title={t("templates.templates.chat.steps.title")}
        elements={[1, 2, 3].map((index) => {
          return {
            title: t(`templates.templates.chat.steps.elements.${index}.title`),
            description: t(
              `templates.templates.chat.steps.elements.${index}.description`
            ),
          };
        })}
      />

      <VideoPreview
        id={"live-chat"}
        title={t("templates.templates.chat.1.title")}
        paragraph={t("templates.templates.chat.1.subtitle")}
        storiesPosition={"left"}
        stories={[
          {
            relativePath: "templates/templates/chat/live-chat-superpowers.mov",
          },
        ]}
        cta={{
          link: "/live-chat/",
          text: t("templates.templates.chat.1.cta"),
        }}
      />

      <VideoPreview
        id={"live-chat-autosearch"}
        title={t("templates.templates.chat.2.title")}
        paragraph={t("templates.templates.chat.2.subtitle")}
        storiesPosition={"right"}
        stories={[
          {
            relativePath: "templates/templates/chat/pre-chat-survey.mov",
          },
        ]}
        cta={{
          link: "/live-chat/",
          text: t("templates.templates.chat.2.cta"),
        }}
      />

      <VideoPreview
        id={"live-chat-surveys"}
        title={t("templates.templates.chat.3.title")}
        paragraph={t("templates.templates.chat.3.subtitle")}
        storiesPosition={"left"}
        stories={[
          {
            relativePath: "templates/templates/chat/autosearch.mov",
          },
        ]}
        cta={{
          link: "/live-chat/",
          text: t("templates.templates.chat.3.cta"),
        }}
      />

      <RegisterForCustomerService />

      <Customers />

      <BlogPostsBox
        category={"live-chat"}
        title={"Live Chat Articles"}
        maxPosts={3}
        postsPerRow={3}
        currentPostSlug={"/"}
      />
    </GlobalWrapper>
  );
};

export default ChatTemplate;
