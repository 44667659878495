import styled from "styled-components";
import { Col, media } from "styled-bootstrap-grid";

const TemplateContainer = styled(Col)`
  padding: 64px 64px 64px 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  align-items: center;

  ${media.sm`
    padding: 16px;
  `}

  ${media.xs`
    padding: 16px;
  `}
`;

export { TemplateContainer };
