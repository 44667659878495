import styled from "styled-components";

const NotificationMessages = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const NotificationMessage = styled.div`
  background: white;
  border-radius: 16px;
  box-shadow: rgb(0 0 0 / 9%) 0 1px 20px 9px;
  padding: 16px;
  width: 288px;
  max-height: 400px;
  overflow: hidden;
  word-break: break-word;

  * {
    color: rgb(26, 34, 44);
    font-family: Roboto, "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 14px;
    min-height: 0;
    margin: 0;
  }

  p {
    min-height: 0 !important;
  }

  iframe {
    width: 100%;
    margin: 8px 0;
  }
`;

const Avatar = styled.img`
  width: 50px;
  height: 50px;
  display: inline-block;
`;

const AuthorName = styled.div`
  color: #9fa8a8;
  font-size: 12px;
  font-weight: bold;
`;

const NotificationContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  gap: 20px;

  p {
    min-height: 24px;
  }

  img {
    border-radius: 50%;
  }
`;

export {
  NotificationMessages,
  NotificationMessage,
  NotificationContainer,
  Avatar,
  AuthorName,
};
