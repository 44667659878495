import { Direction, DirectionType } from "app-types/messenger";
import styled, { css } from "styled-components";
import { NotificationContainer } from "./Notification/index.style";

const CTAButton = styled.div<{ accentColor?: string }>`
  display: flex;
  background: center center ${(props) => props.accentColor};
  margin: 0 auto;
  border-radius: 8px;
  color: white;
  font-size: 16px;
  -webkit-box-align: center;
  align-items: center;
  border: 0;
  padding: 0;
  outline: none;
  min-height: 48px;
  width: 100%;
  -webkit-box-pack: center;
  justify-content: center;
`;

const Element = css`
  padding: 16px;
  background-color: white;
  border-radius: 8px;
  color: #282828;
  font-size: 14px;
  font-weight: 400;
  outline: none;
  border: 1px solid #dddddd;
  width: 100%;
  margin-bottom: 16px;
`;

const Textbox = styled.input<{ withArrowRight?: boolean }>`
  ${Element}
`;

const MultilineTextbox = styled.textarea`
  ${Element}
`;

const StyledMessengerPreview = styled.div<{ direction?: DirectionType }>`
  display: flex;
  width: 360px;
  flex-direction: column;
  gap: 20px;

  ${(props) =>
    props.direction &&
    props.direction === Direction.Right &&
    css`
      align-content: flex-end;
      align-items: flex-end;

      ${NotificationContainer} {
        flex-direction: row;
        justify-content: flex-start;
      }
    `}
`;

export {
  StyledMessengerPreview,
  CTAButton,
  MultilineTextbox,
  Textbox,
  Element,
};
