export enum Direction {
  Right = "right",
  Left = "left",
}

export enum WidgetIconStyle {
  Smile = 0,
  Default = 1,
  Headphones = 2,
  Dots = 3,
  Lifebuoy = 4,
  QuestionMark = 5,
}

export type DirectionType = `${Direction}`;
