import React from "react";
import { useTranslation } from "react-i18next";
import { CTAButton } from "../index.style";
import {
  AuthorName,
  Avatar,
  NotificationContainer,
  NotificationMessage,
  NotificationMessages,
} from "./index.style";

interface NotificationProps {
  messages: string[];
  accountId?: number;
  name?: string;
  accentColor: string;
}

const Notification = ({
  messages,
  accountId,
  name,
  accentColor,
}: NotificationProps) => {
  const { t } = useTranslation();

  return (
    <NotificationContainer>
      <Avatar
        alt={"Live chat message account profile picture"}
        src={`https://pictures.customerly.io/accounts/${accountId}/250`}
      />

      <NotificationMessages>
        {messages.map((message, index) => {
          return (
            <NotificationMessage key={`notification-message-${index}`}>
              <AuthorName>
                {t(
                  "components.ui.extended.MessengerPreview.Notification.author",
                  {
                    sender: name,
                    appName: "Customerly",
                  }
                )}
              </AuthorName>
              <div
                dangerouslySetInnerHTML={{
                  __html: message,
                }}
              />
            </NotificationMessage>
          );
        })}
        <CTAButton accentColor={accentColor}>
          {t("components.ui.extended.MessengerPreview.Notification.replyTo", {
            sender: name,
          })}
        </CTAButton>
      </NotificationMessages>
    </NotificationContainer>
  );
};

export default Notification;
